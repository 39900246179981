import { UploadProps } from 'antd';
import { useEffect, FocusEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { alertSuccess, validateZipcode } from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import moment from 'moment';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';

export type ReceivedProps = {
  screenType: "create" | "edit" | "detail";
};
const useQuoteForm = (props: ReceivedProps) => {
  const { screenType } = props;
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    getValues,
    setError,
  } = useForm();
  const id = useParams();
  const navigate = useNavigate();
  let storage = localStorage.getItem('user');
  const user = storage !== null ? JSON.parse(storage) : null;

  const dummyRequest: UploadProps["customRequest"] = async ({
    file,
    onSuccess
  }) => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess("ok");
      }
    }, 0);
  };


  const handleClose = () => {
    clearErrors();
    navigate('/quote/list');
  };

  const submitData = async (value: any, evt: any) => {
    // evt.preventDefault()
    if (value?.zipCode) {
      const isZipcodeFormat = validateZipcode(value?.zipCode);
      if (!isZipcodeFormat) {
        setError('zipCode', {
          type: 'validate',
          message:
            '郵便番号は数字のみで、000-0000の形式でなければなりません。',
        });
        return;
      }
    }

    if (value?.zipCodeConstruction) {
      const isZipcodeFormat = validateZipcode(value?.zipCodeConstruction);
      if (!isZipcodeFormat) {
        setError('zipCodeConstruction', {
          type: 'validate',
          message:
            '郵便番号は数字のみで、000-0000の形式でなければなりません。',
        });
        return;
      }
    }
    let formData = new FormData();
    formData.append('title', '');
    formData.append('note', value?.note || '');
    formData.append('status', value?.status);
    formData.append('quote_type', value?.quote_type);
    formData.append('desired_date', value?.dateTime);
    formData.append('zipcode', value?.zipCodeConstruction);
    formData.append('address', value?.construction_address);
    formData.append('content', value?.content);
    formData.append('project_title', value?.project_title);
    formData.append('customer[first_name]', value?.first_name);
    formData.append('customer[last_name]', value?.last_name);
    formData.append('customer[first_name_katakana]', value?.first_name_kana);
    formData.append('customer[last_name_katakana]', value?.last_name_kana);
    formData.append('customer[address]', value?.address);
    formData.append('customer[zipcode]', value?.zipCode);
    formData.append('customer[phone_number]', value?.phone);
    try {
      if (screenType === "create") {
        const res = await quoteApi.createQuote(user?.access_token, formData);
        if (res) {
          alertSuccess(
            '見積依頼内容の更新に成功しました。'
          );
        }
      } else {
        const res = await quoteApi.updateQuote(user?.access_token, formData, id.id);
        if (res) {
          alertSuccess(
            '見積依頼内容の更新に成功しました。'
          );

          navigate(`/quote/list`);
          setValue('note', '');
          setValue('title', '');
          setValue('project_title', '');
          setValue('address', '');
          setValue('zipcode', '');
          setValue('desired_date', undefined);
          setValue('content', '');
        }
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };



  const getDetail = async () => {
    try {
      const res = await quoteApi.getQuoteDetail(user?.access_token, id.id);
      if (res) {
        const data = res.data.quote;
        if (data?.quote_type === null) {
          setValue('quote_type', 'interior');
        } else {
          setValue('quote_type', data?.quote_type);
        }
        setValue('title', data?.title);
        setValue('note', data?.note);
        setValue('construction_address', data?.address);
        setValue('status', data?.status);
        setValue('content', data?.content);
        setValue('zipCode', data?.customer?.zipcode);
        setValue('zipCodeConstruction', data?.zipcode);
        setValue('project_title', data?.project_title);
        setValue('first_name', data?.customer?.first_name);
        setValue('last_name', data?.customer?.last_name);
        setValue('first_name_kana', data?.customer?.first_name_katakana);
        setValue('last_name_kana', data?.customer?.last_name_katakana);
        setValue('address', data?.customer?.address);
        setValue('phone', data?.customer?.phone_number);
        data?.desired_date &&
          setValue('dateTime', moment(data.desired_date));
      }
    } catch (error) {
      //
    } finally {
    }
  };

  const handleValidateWhitespace: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
    if (onlyContainWhitespaces) {
      setValue(e.target.name, '');
    } else {
      setValue(e.target.name, e.target.value.trim());
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  useEffect(() => {
    setValue('quote_type', 'interior');
    setValue('status', 'default');
  }, []);

  return {
    ...props,
    handleSubmit,
    submitData,
    control,
    dummyRequest,
    setValue,
    register,
    clearErrors,
    watch,
    getValues,
    setError,
    errors,
    navigate,
    handleValidateWhitespace,
    screenType
  };
};

export type Props = ReturnType<typeof useQuoteForm>;

export default useQuoteForm;
