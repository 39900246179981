import { TabsProps } from "antd";
import { FocusEventHandler, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { alertError, alertSuccessPopup } from "utils/helper/appHelper";
import { validateFullWhitespace } from "utils/validation/validatorHelper";
import querystring from 'query-string'
import { formatCurrency } from "utils/helper/currency";
import moment from "moment";
import invoiceApi from "services/invoiceApi";
import quoteApi from "services/quoteApi";
import companyInfoService from "services/companyInfoService";
import { Controller } from 'react-hook-form';

export type ReceivedProps = Record<never, never>;

const sampleItem = {
    title: '',
    content: [''],
    price_tab1: [''],
    quantity_tab1: [''],
    unit: ['式'],
    budgetQuantity: [''],
    budgetPrice: [''],
    tax: ['10']
}

const useInvoiceHome = (props: ReceivedProps) => {
    const [curTab, setCurTab] = useState('1')
    const [searchParam] = useSearchParams()
    const location = useLocation()
    const [filesListPdf, setFilesListPdf]: any = useState([]);
    const [invoiceDetail, setInvoiceDetail]: any = useState([])
    const [invoice, setInvoice]: any = useState()
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [totalItemPrice, setTotalItemPrice] = useState<number[]>([])
    const [totalItemPriceBudget, setTotalItemPriceBudget] = useState<number[]>([])
    const [totalPriceBudget, setTotalPriceBudget] = useState(0)
    const [totalItemBudgetPrice, setTotalItemBudgetPrice] = useState<number[]>([])
    const [totalItemRevenue, setTotalItemRevenue] = useState<number[]>([])
    const [totalItemPriceActual, setTotalItemPriceActual] = useState<number[]>([])
    const [totalItemEstimatePrice, setTotalItemEstimatePrice] = useState<number[]>([])
    const [totalItemRevenueActual, setTotalItemRevenueActual] = useState<number[]>([])
    const [totalPriceActual, setTotalPriceActual] = useState(0)
    const [totalEstimatePrice, setTotalEstimatePrice] = useState(0)
    const [totalRevenueActual, setTotalRevenueActual] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalBudgetPrice, setTotalBudgetPrice] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [quoteDetail, setQuoteDetail]: any = useState()
    const navigate = useNavigate()
    const queryObj = querystring.parse(location.search)
    const [arrInvoiceEstimate, setArrInvoiceEstimate] = useState([true])
    const [arrInvoiceBudget, setArrInvoiceBudget] = useState([true])
    const [totalFile, setTotalFile]: any = useState([]);
    const [filePdfList, setFilePdfList]: any = useState([]);
    const [arrInvoiceActual, setArrInvoiceActual] = useState([true])

    const onChange = (key: string) => {
        setCurTab(key)
    };

    const formHandler = useForm()
    const {
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
    } = formHandler
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Tab 1',
            children: 'Content of Tab Pane 1',
        },
        {
            key: '2',
            label: 'Tab 2',
            children: 'Content of Tab Pane 2',
        },
        {
            key: '3',
            label: 'Tab 3',
            children: 'Content of Tab Pane 3',
        },
    ];

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };

    const getDetail = async (arrEdit?) => {
        try {
            const res = await invoiceApi.getInvoiceDetail(user?.access_token, queryObj?.invoice_id)
            if (res) {
                const data = res.data.invoice
                setInvoice(data)
                setValue('companyName', data.company_name)
                setValue('address', data.company_address)
                setValue('bankAccount', data.company_bank_account)
                setValue('customer', data.customer_name)
                setValue('address2', data.customer_address)
                setValue('callNum', data.id)
                setValue('zipCode', data.zipcode)
                setValue('projectTitle', data.project_title)
                setValue('quoteType', data?.quote?.quote_type)
                console.log('data', data)
                let arrTmp: any = []
                const pdfArr: any = [];

                if (data?.estimate_pdf_url) {
                    await fetch(data?.files)
                        .then((res) => res.blob())
                        .then((myBlob) => {
                            const myFile: any = new File(
                                [myBlob],
                                data?.estimate_pdf_url?.file_path,
                                {
                                    type: myBlob.type,
                                },
                            );
                            pdfArr.push(myFile);
                        });
                }

                if (data?.invoice_pdf_url) {
                    await fetch(data?.files)
                        .then((res) => res.blob())
                        .then((myBlob) => {
                            const myFile: any = new File(
                                [myBlob],
                                data?.invoice_pdf_url?.file_path,
                                {
                                    type: myBlob.type,
                                },
                            );
                            pdfArr.push(myFile);
                        });
                }
                setFilesListPdf(pdfArr);
                if (data.payment_date) {
                    setValue('paymentDate', moment(data.payment_date))
                }
                if (data.invoice_date) {
                    setValue('date', moment(data.invoice_date))
                }
                setInvoiceDetail(data.invoice_details)
                const detail = data.invoice_details
                for (let i = 0; i < detail?.length; i++) {
                    const countTmp = arrTmp[arrTmp.length - 1] || 0
                    arrTmp.push(true)
                    setValue(`titleInv.${i}.title`, detail[i]?.content)
                    setValue(`titleInv_tab2.${i}.title`, detail[i]?.content)
                    setValue(`titleInv_tab3.${i}.title`, detail[i]?.content)
                    const children = detail[i]?.children
                    for (let k = 0; k < children?.length; k++) {
                        setValue(`titleInv.${i}.content.${k}`, children[k]?.content)
                        setValue(`titleInv.${i}.price_tab1.${k}`, children[k]?.price_estimate ? formatCurrency(children[k]?.price_estimate) : '')
                        setValue(`titleInv.${i}.budgetPrice.${k}`, children[k]?.price_budget ? String(children[k]?.price_budget?.toLocaleString('en-US')) : '')
                        setValue(`titleInv.${i}.quantity_tab1.${k}`, children[k]?.quantity_estimate ?
                            String(children[k]?.quantity_estimate?.toLocaleString('en-US')) : '')
                        setValue(`titleInv.${i}.budgetQuantity.${k}`, children[k]?.quantity_budget ?
                            String(children[k]?.quantity_budget?.toLocaleString('en-US')) : '')
                        setValue(`titleInv.${i}.unit.${k}`, children[k]?.unit_estimate)
                        setValue(`titleInv.${i}.tax.${k}`, '10')

                        setValue(`titleInv_tab2.${i}.content.${k}`, children[k]?.content)
                        setValue(`titleInv_tab2.${i}.price.${k}`, children[k]?.price_budget ? formatCurrency(children[k]?.price_budget) : '')
                        setValue(`titleInv_tab2.${i}.quantity.${k}`, children[k]?.quantity_budget ? String(children[k]?.quantity_budget?.toLocaleString('en-US')) : '')
                        setValue(`titleInv_tab2.${i}.unit.${k}`, children[k]?.unit_budget)
                        setValue(`titleInv_tab2.${i}.tax.${k}`, '10')

                        setValue(`titleInv_tab3.${i}.content.${k}`, children[k]?.content)
                        setValue(`titleInv_tab3.${i}.price.${k}`, children[k]?.price_actual ? formatCurrency(children[k]?.price_actual) : '')
                        setValue(`titleInv_tab3.${i}.estimatePrice.${k}`, children[k]?.price_estimate ? String(children[k]?.price_estimate?.toLocaleString('en-US')) : '')
                        setValue(`titleInv_tab3.${i}.quantity.${k}`, children[k]?.quantity_actual ? String(children[k]?.quantity_actual?.toLocaleString('en-US')) : '')
                        setValue(`titleInv_tab3.${i}.estimateQuantity.${k}`, children[k]?.quantity_estimate ? String(children[k]?.quantity_estimate?.toLocaleString('en-US')) : '')
                        setValue(`titleInv_tab3.${i}.unit.${k}`, children[k]?.unit_actual)
                        setValue(`titleInv_tab3.${i}.tax.${k}`, '10')
                    }
                }
                setArrInvoiceEstimate(arrTmp)
                setArrInvoiceBudget(arrTmp)
                setArrInvoiceActual(arrTmp)
                calTotalEstimate()
                calTotalBudget()
                calTotalActual()
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const calTotalBudget = () => {
        let totalPriceTmp = 0
        let totalPriceArr: number[] = []
        if (getValues('titleInv_tab2')?.length > 0) {
            for (let i = 0; i < getValues('titleInv_tab2')?.length; i++) {

                let totalPriceItemTmp = 0
                for (let k = 0; k < getValues(`titleInv_tab2.${i}.tax`)?.length; k++) {
                    let price = getValues(`titleInv_tab2.${i}.price.${k}`) ?
                        getValues(`titleInv_tab2.${i}.price.${k}`).replace(/[¥,]/g, '') : ''
                    let quantity = getValues(`titleInv_tab2.${i}.quantity.${k}`) ?
                        getValues(`titleInv_tab2.${i}.quantity.${k}`).replace(/,/g, '') : ''
                    totalPriceTmp += Number(price) * Number(quantity)
                    totalPriceItemTmp += Number(price) * Number(quantity)

                }
                totalPriceArr.push(totalPriceItemTmp)

            }
            setTotalItemPriceBudget(totalPriceArr)
            setValue('priceTotalBudget', totalPriceTmp)
            setTotalPriceBudget(totalPriceTmp)
        } else {
            setTotalItemPriceBudget([])
            setValue('priceTotalBudget', 0)
            setTotalPriceBudget(0)
        }
    }

    const calTotalEstimate = () => {
        let totalPriceTmp = 0
        let totalBudgetTmp = 0
        let totalRevenueTmp = 0
        let totalPriceArr: number[] = []
        let totalBudgetArr: number[] = []
        let totalRevenueArr: number[] = []
        if (getValues('titleInv')?.length > 0) {
            for (let i = 0; i < getValues('titleInv')?.length; i++) {

                let totalPriceItemTmp = 0
                let totalBudgetItemTmp = 0
                let totalRevenueItemTmp = 0
                for (let k = 0; k < getValues(`titleInv.${i}.tax`)?.length; k++) {
                    let price = getValues(`titleInv.${i}.price_tab1.${k}`) ?
                        getValues(`titleInv.${i}.price_tab1.${k}`).replace(/[¥,]/g, '') : ''
                    let budgetPrice = getValues(`titleInv.${i}.budgetPrice.${k}`) ?
                        getValues(`titleInv.${i}.budgetPrice.${k}`).replace(/,/g, '') : ''
                    let quantity = getValues(`titleInv.${i}.quantity_tab1.${k}`) ?
                        getValues(`titleInv.${i}.quantity_tab1.${k}`).replace(/,/g, '') : ''
                    let budgetQuantity = getValues(`titleInv.${i}.budgetQuantity.${k}`) ?
                        getValues(`titleInv.${i}.budgetQuantity.${k}`).replace(/,/g, '') : ''
                    totalPriceTmp += Number(price) * Number(quantity)
                    totalBudgetTmp += Number(budgetPrice) * Number(budgetQuantity)
                    totalRevenueTmp += (Number(price) * Number(quantity)) - (Number(budgetPrice) * Number(budgetQuantity))
                    totalPriceItemTmp += Number(price) * Number(quantity)
                    totalBudgetItemTmp += Number(budgetPrice) * Number(budgetQuantity)
                    totalRevenueItemTmp += (Number(price) * Number(quantity)) - (Number(budgetPrice) * Number(budgetQuantity))

                }
                totalPriceArr.push(totalPriceItemTmp)
                totalBudgetArr.push(totalBudgetItemTmp)
                totalRevenueArr.push(totalRevenueItemTmp)

            }

            setTotalItemPrice(totalPriceArr)
            setTotalItemBudgetPrice(totalBudgetArr)
            setTotalItemRevenue(totalRevenueArr)
            setValue('priceTotal', totalPriceTmp)
            setTotalPrice(totalPriceTmp)
            setTotalBudgetPrice(totalBudgetTmp)
            setTotalRevenue(totalRevenueTmp)
        } else {
            setTotalItemPrice([])
            setTotalItemBudgetPrice([])
            setTotalItemRevenue([])
            setValue('priceTotal', 0)
            setTotalPrice(0)
            setTotalBudgetPrice(0)
            setTotalRevenue(0)
        }
    }

    const submitData = (value) => {
        if (curTab === '1') {
            submitDataInvoice(value)
        } else if (curTab === '2') {
            submitDataBudget(value)
        } else if (curTab === '3') {
            submitDataActual(value)
        }
    }

    const calTotalActual = () => {
        let totalPriceActualTmp = 0
        let totalEstimateTmp = 0
        let totalRevenueActualTmp = 0
        let totalPriceActualArr: number[] = []
        let totalEstimateArr: number[] = []
        let totalRevenueActualArr: number[] = []
        if (getValues('titleInv_tab3')?.length > 0) {
            for (let i = 0; i < getValues('titleInv_tab3')?.length; i++) {

                let totalPriceActualItemTmp = 0
                let totalEstimateItemTmp = 0
                let totalRevenueActualItemTmp = 0
                for (let k = 0; k < getValues(`titleInv_tab3.${i}.tax`)?.length; k++) {
                    let price = getValues(`titleInv_tab3.${i}.price.${k}`) ?
                        getValues(`titleInv_tab3.${i}.price.${k}`).replace(/[¥,]/g, '') : ''
                    let estimatePrice = getValues(`titleInv_tab3.${i}.estimatePrice.${k}`) ?
                        getValues(`titleInv_tab3.${i}.estimatePrice.${k}`).replace(/,/g, '') : ''
                    let quantity = getValues(`titleInv_tab3.${i}.quantity.${k}`) ?
                        getValues(`titleInv_tab3.${i}.quantity.${k}`).replace(/,/g, '') : ''
                    let estimateQuantity = getValues(`titleInv_tab3.${i}.estimateQuantity.${k}`) ?
                        getValues(`titleInv_tab3.${i}.estimateQuantity.${k}`).replace(/,/g, '') : ''
                    totalPriceActualTmp += Number(price) * Number(quantity)
                    totalEstimateTmp += Number(estimatePrice) * Number(estimateQuantity)
                    totalRevenueActualTmp += ((Number(estimatePrice) * Number(estimateQuantity) - Number(price) * Number(quantity)))
                    totalPriceActualItemTmp += Number(price) * Number(quantity)
                    totalEstimateItemTmp += Number(estimatePrice) * Number(estimateQuantity)
                    totalRevenueActualItemTmp += (Number(estimatePrice) * Number(estimateQuantity)) - (Number(price) * Number(quantity))

                }
                totalPriceActualArr.push(totalPriceActualItemTmp)
                totalEstimateArr.push(totalEstimateItemTmp)
                totalRevenueActualArr.push(totalRevenueActualItemTmp)

            }

            setTotalItemPriceActual(totalPriceActualArr)
            setTotalItemEstimatePrice(totalEstimateArr)
            setTotalItemRevenueActual(totalRevenueActualArr)
            setValue('priceTotalBudget', totalPriceActualTmp)
            setTotalPriceActual(totalPriceActualTmp)
            setTotalEstimatePrice(totalEstimateTmp)
            setTotalRevenueActual(totalRevenueActualTmp)
        } else {
            setTotalItemPriceActual([])
            setTotalItemEstimatePrice([])
            setTotalItemRevenueActual([])
            setValue('priceTotalBudget', 0)
            setTotalPriceActual(0)
            setTotalEstimatePrice(0)
            setTotalRevenueActual(0)
        }
    }

    const submitDataActual = async (value) => {
        const arrTmp: any = []
        const priceTotalBudget = getValues('priceTotalBudget')
        if (queryObj.invoice_id) {
            for (let i = 0; i < value.titleInv_tab3.length; i++) {
                if (value.titleInv_tab3[i]?.title) {
                    if (value.titleInv_tab3[i].content && value.titleInv_tab3[i].content.length === 0) {
                        alertError("入力が不十分です。全ての項目を入力してください。")
                        return
                    }
                    const arrItemTmp: any = []
                    for (let k = 0; k < value.titleInv_tab3[i]?.content?.length; k++) {
                        if (value.titleInv_tab3[i].content[k] && value.titleInv_tab3[i].unit[k] &&
                            value.titleInv_tab3[i].quantity[k] && value.titleInv_tab3[i].price[k]) {
                            arrItemTmp.push({
                                content: value.titleInv_tab3[i].content[k],
                                // tax: 10,
                                unit_estimate: value.titleInv_tab3[i]?.unit[k] || '式',
                                quantity_estimate: invoiceDetail[i]?.children[k]?.quantity_estimate || 0,
                                price_estimate: invoiceDetail[i]?.children[k]?.price_estimate || 0,
                                unit_budget: value.titleInv_tab3[i]?.unit[k] || '式',
                                quantity_budget: invoiceDetail[i]?.children[k]?.quantity_budget || 0,
                                price_budget: invoiceDetail[i]?.children[k]?.price_budget || 0,
                                unit_actual: value.titleInv_tab3[i]?.unit[k] || '式',
                                quantity_actual: String(value.titleInv_tab3[i]?.quantity[k]).replace(/,/g, ''),
                                price_actual: String(value.titleInv_tab3[i]?.price[k]).replace(/[¥,]/g, ''),
                            })
                        } else {
                            alertError("入力が不十分です。全ての項目を入力してください。")
                            return
                        }
                    }
                    arrTmp.push({
                        content: value.titleInv_tab3[i].title,
                        children: arrItemTmp
                    })
                } else {
                    alertError("入力が不十分です。全ての項目を入力してください。")
                    return
                }
            }
        } else {
            for (let i = 0; i < value.titleInv_tab3.length; i++) {
                if (value.titleInv_tab3[i]?.title) {
                    const arrItemTmp: any = []
                    for (let k = 0; k < value.titleInv_tab3[i].content.length; k++) {
                        if (value.titleInv_tab3[i].content[k] && value.titleInv_tab3[i].unit[k] &&
                            value.titleInv_tab3[i].quantity[k] && value.titleInv_tab3[i].price[k]) {
                            arrItemTmp.push({
                                content: value.titleInv_tab3[i].content[k],
                                // tax: 10,
                                unit_estimate: value.titleInv_tab3[i]?.unit[k] || '式',
                                quantity_estimate: value.titleInv_tab3[i]?.quantity[k].replace(/,/g, ''),
                                price_estimate: value.titleInv_tab3[i]?.price[k].replace(/[¥,]/g, ''),
                                unit_budget: value.titleInv_tab3[i]?.unit[k] || '式',
                                quantity_budget: value.titleInv_tab3[i]?.quantity[k].replace(/,/g, ''),
                                price_budget: value.titleInv_tab3[i]?.price[k].replace(/[¥,]/g, ''),
                                unit_actual: value.titleInv_tab3[i]?.unit[k] || '式',
                                quantity_actual: value.titleInv_tab3[i]?.quantity[k].replace(/,/g, ''),
                                price_actual: value.titleInv_tab3[i]?.price[k].replace(/[¥,]/g, ''),
                            })
                        } else {
                            alertError("入力が不十分です。全ての項目を入力してください。")
                            return
                        }
                    }
                    arrTmp.push({
                        content: value.titleInv_tab3[i].title,
                        children: arrItemTmp
                    })
                } else {
                    alertError("入力が不十分です。全ての項目を入力してください。")
                    return
                }
            }
        }
        if (Number(priceTotalBudget) >= 50 && Number(priceTotalBudget) <= 99999999) {
            const body: any = {
                quote_id: queryObj?.quote_id,
                company_name: getValues('companyName'),
                company_address: getValues('address'),
                company_bank_account: getValues('bankAccount'),
                payment_date: moment(getValues('paymentDate')).format('YYYY-MM-DD'),
                invoice_date: moment(getValues('date')).format('YYYY-MM-DD'),
                customer_name: getValues('customer'),
                customer_address: getValues('address2'),
                // invoice_number: getValues('callNum'),
                quote_type: getValues('quoteType'),
                zipcode: getValues('zipCode'),
                project_title: getValues('projectTitle'),
                invoice_details_attributes: arrTmp
            }
            if (queryObj.invoice_id) {
                try {
                    const res = await invoiceApi.updateInvoice(user?.access_token, body, queryObj.invoice_id)
                    if (res) {
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([])
                    }
                } catch (error) {
                    console.log('error', error)
                }
            } else {
                try {
                    const res = await invoiceApi.createInvoice(user?.access_token, body)
                    if (res) {
                        const data = res.data.invoice
                        navigate(
                            `/quote/list/invoice${queryObj?.quote_id ? `?quote_id=${queryObj?.quote_id}&` : '?'}invoice_id=${data?.id}&tab=3`,
                        )
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([])
                    }
                } catch (error) {
                    console.log('error', error)
                }
            }
        } else {
            alertError("合計注文金額は¥50から¥99,999,999までの間である必要があります。")
        }
    };

    const submitDataInvoice = async (value) => {
        const priceTotal = getValues('priceTotal')
        const arrTmp: any = []
        if (queryObj?.invoice_id) {
            for (let i = 0; i < value.titleInv.length; i++) {
                if (value.titleInv[i]?.title) {
                    if (value.titleInv[i].content && value.titleInv[i].content.length === 0) {
                        alertError("入力が不十分です。全ての項目を入力してください。")
                        return
                    }
                    const arrItemTmp: any = []
                    for (let k = 0; k < value.titleInv[i]?.content?.length; k++) {
                        if (value?.titleInv[i]?.content[k] && value?.titleInv[i]?.unit[k] &&
                            value.titleInv[i].quantity_tab1[k] && value.titleInv[i].price_tab1[k] && value.titleInv[i].tax[k]) {
                            arrItemTmp.push({
                                content: value.titleInv[i].content[k],
                                // tax: 10,
                                unit_estimate: value.titleInv[i]?.unit[k] || '式',
                                quantity_estimate: String(value.titleInv[i]?.quantity_tab1[k]).replace(/,/g, ''),
                                price_estimate: String(value.titleInv[i]?.price_tab1[k]).replace(/[¥,]/g, ''),
                                unit_budget: value.titleInv[i]?.unit[k] || '式',
                                quantity_budget: invoiceDetail[i]?.children[k]?.quantity_budget || 0,
                                price_budget: invoiceDetail[i]?.children[k]?.price_budget || 0,
                                unit_actual: value.titleInv[i]?.unit[k] || '式',
                                quantity_actual: invoiceDetail[i]?.children[k]?.quantity_actual || 0,
                                price_actual: invoiceDetail[i]?.children[k]?.price_actual || 0,
                            })
                        } else {
                            alertError("入力が不十分です。全ての項目を入力してください。")
                            return
                        }
                    }
                    arrTmp.push({
                        content: value.titleInv[i].title,
                        children: arrItemTmp
                    })
                } else {
                    alertError("入力が不十分です。全ての項目を入力してください。")
                    return
                }
            }
        } else {
            for (let i = 0; i < value.titleInv.length; i++) {
                if (value.titleInv[i]?.title) {
                    if (value.titleInv[i].content && value.titleInv[i].content.length === 0) {
                        alertError("入力が不十分です。全ての項目を入力してください。")
                        return
                    }
                    const arrItemTmp: any = []
                    for (let k = 0; k < value.titleInv[i].content.length; k++) {
                        if (value.titleInv[i].content[k] && value.titleInv[i].unit[k] &&
                            value.titleInv[i].quantity_tab1[k] && value.titleInv[i].price_tab1[k] && value.titleInv[i].tax[k]) {
                            arrItemTmp.push({
                                content: value.titleInv[i].content[k],
                                // tax: 10,
                                unit_estimate: value.titleInv[i]?.unit[k] || '式',
                                quantity_estimate: value.titleInv[i]?.quantity_tab1[k].replace(/,/g, ''),
                                price_estimate: value.titleInv[i]?.price_tab1[k].replace(/[¥,]/g, ''),
                                unit_budget: value.titleInv[i]?.unit[k] || '式',
                                quantity_budget: value.titleInv[i]?.quantity_tab1[k].replace(/,/g, ''),
                                price_budget: value.titleInv[i]?.price_tab1[k].replace(/[¥,]/g, ''),
                                unit_actual: value.titleInv[i]?.unit[k] || '式',
                                quantity_actual: value.titleInv[i]?.quantity_tab1[k].replace(/,/g, ''),
                                price_actual: value.titleInv[i]?.price_tab1[k].replace(/[¥,]/g, ''),
                            })
                        } else {
                            alertError("入力が不十分です。全ての項目を入力してください。")
                            return
                        }
                    }
                    arrTmp.push({
                        content: value.titleInv[i].title,
                        children: arrItemTmp
                    })
                } else {
                    alertError("入力が不十分です。全ての項目を入力してください。")
                    return
                }
            }
        }
        if (Number(priceTotal) >= 50 && Number(priceTotal) <= 99999999) {
            const body: any = {
                quote_id: queryObj?.quote_id,
                company_name: getValues('companyName'),
                company_address: getValues('address'),
                company_bank_account: getValues('bankAccount'),
                payment_date: moment(getValues('paymentDate')).format('YYYY-MM-DD'),
                invoice_date: moment(getValues('date')).format('YYYY-MM-DD'),
                customer_name: getValues('customer'),
                customer_address: getValues('address2'),
                zipcode: getValues('zipCode'),
                project_title: getValues('projectTitle'),
                quote_type: getValues('quoteType'),
                // address: getValues('address2'),
                // invoice_number: getValues('callNum'),
                invoice_details_attributes: arrTmp
            }
            if (queryObj?.invoice_id) {
                try {
                    const res = await invoiceApi.updateInvoice(user?.access_token, body, queryObj.invoice_id)
                    if (res) {
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([])
                    }
                } catch (error) {
                    console.log('error', error)
                }
            } else {
                try {
                    const res = await invoiceApi.createInvoice(user?.access_token, body)
                    if (res) {
                        const data = res.data.invoice
                        navigate(
                            `/quote/list/invoice${queryObj?.quote_id ? `?quote_id=${queryObj?.quote_id}&` : '?'}` +
                            `invoice_id=${data?.id}&${queryObj?.prev ? `prev=${queryObj?.prev}&` : ''}tab=1`,
                        )
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([])
                    }
                } catch (error) {
                    console.log('error', error)
                }
            }
        } else {
            alertError("合計注文金額は¥50から¥99,999,999までの間である必要があります。")
        }
    };

    const submitDataBudget = async (value) => {
        const priceTotal = getValues('priceTotalBudget')
        const arrTmp: any = []
        if (queryObj.invoice_id) {
            for (let i = 0; i < value.titleInv_tab2.length; i++) {
                if (value.titleInv_tab2[i]?.title) {
                    if (value.titleInv_tab2[i].content && value.titleInv_tab2[i].content.length === 0) {
                        alertError("入力が不十分です。全ての項目を入力してください。")
                        return
                    }
                    const arrItemTmp: any = []
                    for (let k = 0; k < value.titleInv_tab2[i]?.content?.length; k++) {
                        if (value.titleInv_tab2[i].content[k] && value.titleInv_tab2[i].unit[k] &&
                            value.titleInv_tab2[i].quantity[k] && value.titleInv_tab2[i].content[k]) {
                            arrItemTmp.push({
                                content: value.titleInv_tab2[i].content[k],
                                // tax: 10,
                                unit_estimate: value.titleInv_tab2[i]?.unit[k] || '式',
                                quantity_estimate: invoiceDetail[i]?.children[k]?.quantity_estimate || 0,
                                price_estimate: invoiceDetail[i]?.children[k]?.price_estimate || 0,
                                unit_budget: value.titleInv_tab2[i]?.unit[k] || '式',
                                quantity_budget: String(value.titleInv_tab2[i]?.quantity[k]).replace(/,/g, ''),
                                price_budget: String(value.titleInv_tab2[i]?.price[k]).replace(/[¥,]/g, ''),
                                unit_actual: value.titleInv_tab2[i]?.unit[k] || '式',
                                quantity_actual: invoiceDetail[i]?.children[k]?.quantity_actual || 0,
                                price_actual: invoiceDetail[i]?.children[k]?.price_actual || 0,
                            })
                        } else {
                            alertError("入力が不十分です。全ての項目を入力してください。")
                            return
                        }
                    }

                    arrTmp.push({
                        content: value.titleInv_tab2[i].title,
                        children: arrItemTmp
                    })
                } else {
                    alertError("入力が不十分です。全ての項目を入力してください。")
                    return
                }
            }
        } else {
            for (let i = 0; i < value.titleInv_tab2.length; i++) {
                if (value.titleInv_tab2[i]?.title) {
                    const arrItemTmp: any = []
                    for (let k = 0; k < value.titleInv_tab2[i].content.length; k++) {
                        if (value.titleInv_tab2[i].content[k] && value.titleInv_tab2[i].unit[k] &&
                            value.titleInv_tab2[i].quantity[k] && value.titleInv_tab2[i].price[k]) {
                            arrItemTmp.push({
                                content: value.titleInv_tab2[i].content[k],
                                // tax: 10,
                                unit_estimate: value.titleInv_tab2[i]?.unit[k] || '式',
                                quantity_estimate: value.titleInv_tab2[i]?.quantity[k].replace(/,/g, ''),
                                price_estimate: value.titleInv_tab2[i]?.price[k].replace(/[¥,]/g, ''),
                                unit_budget: value.titleInv_tab2[i]?.unit[k] || '式',
                                quantity_budget: value.titleInv_tab2[i]?.quantity[k].replace(/,/g, ''),
                                price_budget: value.titleInv_tab2[i]?.price[k].replace(/[¥,]/g, ''),
                                unit_actual: value.titleInv_tab2[i]?.unit[k] || '式',
                                quantity_actual: value.titleInv_tab2[i]?.quantity[k].replace(/,/g, ''),
                                price_actual: value.titleInv_tab2[i]?.price[k].replace(/[¥,]/g, ''),
                            })
                        } else {
                            alertError("入力が不十分です。全ての項目を入力してください。")
                            return
                        }
                    }
                    arrTmp.push({
                        content: value.titleInv_tab2[i].title,
                        children: arrItemTmp
                    })
                } else {
                    alertError("入力が不十分です。全ての項目を入力してください。")
                    return
                }
            }
        }
        if (Number(priceTotal) >= 50 && Number(priceTotal) <= 99999999) {
            const body: any = {
                quote_id: queryObj?.quote_id,
                company_name: getValues('companyName'),
                company_address: getValues('address'),
                company_bank_account: getValues('bankAccount'),
                payment_date: moment(getValues('paymentDate')).format('YYYY-MM-DD'),
                invoice_date: moment(getValues('date')).format('YYYY-MM-DD'),
                customer_name: getValues('customer'),
                customer_address: getValues('address2'),
                // invoice_number: getValues('callNum'),
                quote_type: getValues('quoteType'),
                zipcode: getValues('zipCode'),
                project_title: getValues('projectTitle'),
                invoice_details_attributes: arrTmp
            }
            if (queryObj.invoice_id) {
                try {
                    const res = await invoiceApi.updateInvoice(user?.access_token, body, queryObj.invoice_id)
                    if (res) {
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([])
                    }
                } catch (error) {
                    console.log('error', error)
                }
            } else {
                try {
                    const res = await invoiceApi.createInvoice(user?.access_token, body)
                    if (res) {
                        const data = res.data.invoice
                        navigate(
                            `/quote/list/invoice${queryObj?.quote_id ? `?quote_id=${queryObj?.quote_id}&` : '?'}invoice_id=${data?.id}&tab=2`,
                        )
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([])
                    }
                } catch (error) {
                    console.log('error', error)
                }
            }
        } else {
            alertError("合計注文金額は¥50から¥99,999,999までの間である必要があります。")
        }
    };


    const getQuoteDetail = async () => {
        try {
            const res = await quoteApi.getQuoteDetail(user?.access_token, queryObj?.quote_id)
            if (res) {
                const data = res.data.quote
                setQuoteDetail(data)
                if (!queryObj?.invoice_id) {
                    setValue('address2', data.address)
                    if (data?.customer?.last_name && data?.customer?.first_name) {
                        setValue('customer', `${data?.customer?.last_name} ${data?.customer?.first_name}`)
                    }
                    setValue('callNum', `${queryObj?.quote_id}`)
                    setValue('zipCode', data?.zipcode)
                    setValue('projectTitle', data?.project_title)
                    if (data?.quote_type === null) {
                        setValue('quoteType', 'interior');
                    } else {
                        setValue('quoteType', data?.quote_type);
                    }
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const getCompanyOwnerInfo = useCallback(async () => {
        try {
            const data = await companyInfoService.getCompanyInfo();
            if (data) {
                setValue(
                    'companyName',
                    data.owner_company?.company_name || '',
                );
                setValue('address', data.owner_company?.address || '');
                setValue('bankAccount', data.owner_company?.bank_number || '');
            }
        } catch (error) {
            //
        }
    }, []);

    useEffect(() => {
        if (queryObj?.invoice_id) {
            getDetail([])
            // if (queryObj?.quote_id) {
            //     getQuoteDetail()
            // } else {
            //     setValue('quoteType', 'interior');
            // }
        } else {
            if (queryObj?.quote_id) {
                getQuoteDetail()
            } else {
                setValue('quoteType', 'interior');
            }
            setValue(`titleInv.0`, sampleItem)
            const curTime = new Date()
            setValue(`date`, new Date())
            if (curTime.getMonth() === 11) {
                setValue(`paymentDate`, new Date(curTime.getFullYear() + 1, 1, 0))
            } else {
                setValue(`paymentDate`, new Date(curTime.getFullYear(), curTime.getMonth() + 2, 0))
            }
            setValue(`titleInv.0.content.0`, '')
            setValue(`titleInv.0.price_tab1.0`, '')
            setValue(`titleInv.0.budgetPrice.0`, '')
            setValue(`titleInv.0.budgetQuantity.0`, '')
            setValue(`titleInv.0.quantity_tab1.0`, '')
            setValue(`titleInv.0.unit.0`, '式')
            setValue(`titleInv.0.tax.0`, '10')

            setValue(`titleInv_tab2.0.content.0`, '')
            setValue(`titleInv_tab2.0.price.0`, '')
            setValue(`titleInv_tab2.0.quantity.0`, '')
            setValue(`titleInv_tab2.0.unit.0`, '式')
            setValue(`titleInv_tab2.0.tax.0`, '10')

            setValue(`titleInv_tab3.0.content.0`, '')
            setValue(`titleInv_tab3.0.price.0`, '')
            setValue(`titleInv_tab3.0.estimatePrice.0`, '')
            setValue(`titleInv_tab3.0.estimateQuantity.0`, '')
            setValue(`titleInv_tab3.0.quantity.0`, '')
            setValue(`titleInv_tab3.0.unit.0`, '式')
            setValue(`titleInv_tab3.0.tax.0`, '10')
            getCompanyOwnerInfo()
        }
    }, [queryObj?.invoice_id])

    useEffect(() => {
        if (searchParam.get('tab')) {
            setCurTab(searchParam.get('tab') as string)
        }
    }, [searchParam])

    useEffect(() => {
        if (filesListPdf === null) {
            setTotalFile(filePdfList);
        } else {
            setTotalFile(filesListPdf?.concat(filePdfList));
        }
    }, [filePdfList, filesListPdf]);

    return {
        ...props,
        onChange,
        items,
        curTab,
        setCurTab,
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        resetField,
        clearErrors,
        errors,
        handleValidateWhitespace,
        submitDataInvoice,
        queryObj,
        arrInvoiceEstimate,
        totalItemPrice,
        totalItemBudgetPrice,
        totalItemRevenue,
        totalPrice,
        totalBudgetPrice,
        totalRevenue,
        getDetail,
        invoice,
        totalFile,
        quoteDetail,
        Controller,
        formHandler,
        setArrInvoiceEstimate,
        setTotalItemPrice,
        setTotalItemBudgetPrice,
        setTotalItemRevenue,
        setTotalPrice,
        setTotalRevenue,
        setTotalBudgetPrice,
        submitData,
        arrInvoiceBudget, setArrInvoiceBudget,
        totalItemPriceBudget, setTotalItemPriceBudget,
        totalPriceBudget, setTotalPriceBudget,
        totalItemPriceActual,
        totalItemEstimatePrice,
        totalItemRevenueActual,
        totalPriceActual,
        totalEstimatePrice,
        totalRevenueActual,
        setTotalItemPriceActual, setTotalItemEstimatePrice, setTotalItemRevenueActual,
        setTotalPriceActual, setTotalEstimatePrice, setTotalRevenueActual,
        arrInvoiceActual,
        setArrInvoiceActual,
    };
};
export type Props = ReturnType<typeof useInvoiceHome>;

export default useInvoiceHome;
