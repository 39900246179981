import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
    Calendar,
    ClipboardText,
    FolderPlus,
    LightBulb,
    Note,
    NotePad,
    UsersFour,
    User,
    WreckingBusinessManagement,
    CaretUp,
    FileArrowDown,
    Vehicle,
} from 'assets/menuIcons';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { BsMailbox2 } from 'react-icons/bs';
import { RootState } from '../../store/index';
import './sidebar.css';
import { isAdmin } from 'utils/helper/workerHelper';
import SettingIcon from 'assets/menuIcons/Setting';
import InvoiceIcon from 'assets/menuIcons/InvoiceIcon';
import PartnerIcon from 'assets/menuIcons/PartnerIcon';

declare let window: any;

const SideBar = (props) => {
    const { setChangeTab, isChangeTab } = props;
    const navigate = useNavigate();

    const { userData } = useSelector((state: RootState) => state.users);

    const [hide, setHide] = useState(false);

    const [path, setPath] = useState('');

    const [isShowSupMenu, setShowSupMenu] = useState(false);
    const isAllow = isAdmin(userData);

    useEffect(() => {
        let pathTemp = window.location.pathname;
        setPath(pathTemp);
        if (pathTemp == '/login') {
            setHide(true);
        }
    });

    const menuItemProps = {
        background: '#7A98BE',
        color: '#FFFFFF',
    };

    const svgProps = {
        strokeColor: '#FFFFFF',
    };

    return (
        <div
            className="sidebar"
            style={{
                display: hide ? 'none' : '',
            }}
        >
            <div className="header-sidebar">
                {process.env.REACT_APP_PROJECT_TITLE}
            </div>
            <div className="menu-list">
                {/* Users */}
                {/* <>
                    {isAllow && (
                        <p
                            className="menu-item d-flex flex-row"
                            onClick={() => {
                                navigate('/users');
                                setChangeTab(!isChangeTab);
                            }}
                            style={{
                                ...(path === '/users' && menuItemProps),
                            }}
                        >
                            <User {...(path === '/users' && svgProps)} />
                            アカウント
                        </p>
                    )}
                </> */}
                {/* Worker list */}
                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/workers');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/workers' ||
                            path === '/workers/detail' ||
                            path === '/workers/create' ||
                            path === '/workers/edit'
                            ? menuItemProps
                            : {}),
                    }}
                >
                    <UsersFour
                        {...((path === '/workers' ||
                            path === '/workers/detail' ||
                            path === '/workers/create' ||
                            path === '/workers/edit') &&
                            svgProps)}
                    />
                    社員
                </p> */}


                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/quote/list');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(((path.includes('/quote/list') || path === '/' || path.includes('/quote')) && !path.includes('/quote/list/invoice')) &&
                            menuItemProps),
                    }}
                >
                    <NotePad
                        {...(((path.includes('/quote/list') || path === '/' || path.includes('/quote')) && !path.includes('/quote/list/invoice')) &&
                            svgProps)}
                    />
                    見積管理
                </p>
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/invoices');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path.includes('/invoice') && menuItemProps),
                    }}
                >
                    <InvoiceIcon {...(path === '/invoices' && svgProps)} />
                    請求管理
                </p>
                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/partners');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path.includes('/partners') && menuItemProps),
                    }}
                >
                    <PartnerIcon {...(path === '/partners' && svgProps)} />
                    協力会社
                </p> */}
                <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/company-info-setting');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path.includes('/company-info-setting') &&
                            menuItemProps),
                    }}
                >
                    <SettingIcon
                        {...(path.includes('/company-info-setting') &&
                            svgProps)}
                    />
                    設定
                </p>

                {/* <p
                    className="menu-item d-flex flex-row"
                    onClick={() => {
                        navigate('/invoice/list');
                        setChangeTab(!isChangeTab);
                    }}
                    style={{
                        ...(path === '/invoice/list' && menuItemProps),
                    }}
                >
                    <NotePad {...(path === '/invoice/list' && svgProps)}/>
                    請求書発行
                </p> */}
            </div>
        </div>
    );
};
export default SideBar;
