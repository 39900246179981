import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import PencilIcon from 'assets/icons/PencilSimpleLine.svg';
import RedTrash from 'assets/icons/Trash.svg';
import { Box, TextField } from '@mui/material';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import EyeIcon from 'assets/icons/Eye.svg';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const typeOptions: SelectSearchOption[] = [
    { value: 'all', name: 'すべて' },
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: '#1C1E21',
        fontWeight: 600,
        padding: '12px 14px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 14px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LeftOutlinedCustom = styled(LeftOutlined)``;

interface IInvoiceData {
    id: number;
    company_address: string;
    company_bank_account: string;
    company_name: string;
    created_at: string;
    customer_address: string;
    customer_name: string;
    invoice_date: string;
    invoice_details: unknown[];
    invoice_number: string;
    line_user_avatar?: string | null;
    line_user_name: string;
    payment_date: string;
    payment_type?: string | null;
    quote_id: number;
    status: string;
    total_after_tax?: number;
    total_before_tax?: number;
    total_tax?: number;
    type_of_quote: string;
    updated_at?: string;
    customer: {
        last_name: string;
        first_name: string;
    };
    total_estimate_before_tax: number;
    total_estimate_after_tax: number;
}

interface ISearchValues {
    filter_value: string;
    invoice_start_date: string;
    invoice_end_date: string;
    payment_start_date: string;
    payment_end_date: string;
}

const initialSearchValues: ISearchValues = {
    filter_value: '',
    invoice_start_date: '',
    invoice_end_date: '',
    payment_start_date: '',
    payment_end_date: '',
};

const PartnerListDetail: React.FC = () => {
    const [invoices, setInvoices] = useState<IInvoiceData[]>([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);
    const [searchValues, setSearchValues] =
        useState<ISearchValues>(initialSearchValues);
    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };
    return (
        <>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column w-full">
                    <p className="text-[20px] font-bold">案件一覧</p>
                    <div className="filterSection flex items-end mb-3">
                        <div className="w-[27%]">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                キーワード
                            </p>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    '.input-search': {
                                        border: '1px solid #cdd1d5',
                                        borderTopLeftRadius: '2px',
                                        borderBottomLeftRadius: '2px',
                                        marginBottom: '14.5px',
                                        paddingLeft: '14px',
                                    },
                                    '.MuiTextField-root': {
                                        border: '1px solid #cdd1d5',
                                        width: '100%',
                                        borderTopLeftRadius: '2px',
                                        borderBottomLeftRadius: '2px',
                                        paddingLeft: '14px',
                                    },
                                    '.MuiInputBase-input': {
                                        height: '38px',
                                        padding: '0px !important',
                                    },
                                    '.MuiButtonBase-root': {
                                        minWidth: '0px',
                                    },
                                }}
                            >
                                <TextField
                                    autoComplete="off"
                                    placeholder="検索"
                                    type="text"
                                    value={searchValues.filter_value}
                                    onChange={(evt) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            filter_value: evt.target.value,
                                        }))
                                    }
                                />
                            </Box>
                        </div>
                        <div className="w-[27%] ml-4">
                            <Box>
                                <p className="!mb-[6px] text-[14px] font-medium">
                                    日付
                                </p>
                                <RangePicker
                                    placeholder={['開始日', '終了日']}
                                    onChange={(dates, dateStrings) => {
                                        const [start, end] = dateStrings;
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            invoice_start_date: start,
                                            invoice_end_date: end,
                                        }));
                                    }}
                                    className="max-h-[38px] w-full"
                                />
                            </Box>
                        </div>
                        <div className="w-[27%] ml-4">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                エリア
                            </p>
                            <Box
                                sx={{
                                    '.select-search__input': {
                                        border: '1px solid #D9D9D9',
                                        boxShadow: 'none',
                                    },
                                    '.select-search__options': {
                                        paddingLeft: 0,
                                    },
                                    '.select-search__option.is-selected': {
                                        background: '#215493',
                                    },
                                }}
                            >
                                <SelectSearch
                                    options={[]}
                                    search
                                    emptyMessage={'該当なし'}
                                    // value={typeSearchValue}
                                    onChange={(value) => {
                                        // setPage(1);
                                        // setTypeSearchValue((prev) => {
                                        //     if (prev === value) {
                                        //         return 'all';
                                        //     }
                                        //     return value;
                                        // });
                                    }}
                                />
                            </Box>
                        </div>

                        <button
                            // onClick={handleSearchInvoices}
                            className="modal-create-user-button2 !w-[19%] !h-[38px] !rounded-[3px] ml-[46px] mb-[2px]"
                        >
                            検索
                        </button>
                    </div>
                    <div className="flex text-[14px] justify-end gap-x-[80px] font-semibold">
                        <p>案件合計: <span className="text-[#215493]">23</span></p>
                        <p>金額合計 <span className="text-[#F5222D]">¥23,000,000</span></p>
                    </div>
                    <div className="table-workers d-flex flex-column">
                        <div className="table-content d-flex flex-column">
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 900 }}
                                    aria-label="customized table "
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '5%' }}
                                            >
                                                <div>番号</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '11%' }}
                                            >
                                                <div>案件ID</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '15%' }}
                                            >
                                                <div>案件名</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '8%' }}
                                            >
                                                <div>案件管理者</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '12%' }}
                                            >
                                                <div>完了日</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '13%' }}
                                            >
                                                <div>請求状況</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '8%' }}
                                            >
                                                <div>請負金額</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '8%' }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {invoices.length > 0 &&
                                            invoices.map((row, idx) => {
                                                return (
                                                    <StyledTableRow
                                                        key={row.id}
                                                    >
                                                        <StyledTableCell
                                                            className="!text-left"
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                                width: '5%',
                                                            }}
                                                        ></StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '11%',
                                                            }}
                                                        >
                                                            <div></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '15%',
                                                            }}
                                                        >
                                                            <div></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '8%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            ></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '12%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            ></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '13%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            ></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '11%',
                                                            }}
                                                        >
                                                            <div></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '8%',
                                                            }}
                                                        >
                                                            <div className="flex justify-end items-center">
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 8px 0px 4px',
                                                                    }}
                                                                    // onClick={() =>
                                                                    //     navigate(
                                                                    //         `/quote/list/invoice/${row.quote_id}?invoice_id=${row.id}`,
                                                                    //     )
                                                                    // }
                                                                >
                                                                    <img
                                                                        src={
                                                                            PencilIcon
                                                                        }
                                                                        alt="pencil icon"
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 4px',
                                                                    }}
                                                                    // onClick={() =>
                                                                    //     navigate(
                                                                    //         `/quote/${row.id}`,
                                                                    //     )
                                                                    // }
                                                                >
                                                                    <img
                                                                        src={
                                                                            EyeIcon
                                                                        }
                                                                        alt="eye icon"
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 4px',
                                                                    }}
                                                                    // onClick={() => {
                                                                    //     openDelete();
                                                                    //     setSelectedInvoice(
                                                                    //         row,
                                                                    //     );
                                                                    // }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            RedTrash
                                                                        }
                                                                        alt="red trash icon"
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {invoices.length === 0 && (
                                    <div className="text-center p-[10px] w-full">
                                        データがありません。
                                    </div>
                                )}
                            </TableContainer>
                        </div>
                    </div>

                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlinedCustom
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]':
                                            statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartnerListDetail;
