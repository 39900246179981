import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import invoiceApi from 'services/invoiceApi';
import PencilIcon from 'assets/icons/PencilSimpleLine.svg';
import RedTrash from 'assets/icons/Trash.svg';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import { useModal } from 'react-hooks-use-modal';
import { CloseOutlined } from '@mui/icons-material';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import EyeIcon from 'assets/icons/Eye.svg';
import PlusBlue from 'assets/icons/plus-blue.svg';
import {
    alertError,
    alertSuccess,
    alertSuccessPopup,
} from 'utils/helper/appHelper';
import SelectSearch, { SelectSearchOption } from 'react-select-search';

const typeOptions: SelectSearchOption[] = [
    { value: 'all', name: 'すべて' },
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: '#1C1E21',
        fontWeight: 600,
        padding: '12px 14px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 14px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LeftOutlinedCustom = styled(LeftOutlined)``;

interface IInvoiceData {
    id: number;
    company_address: string;
    company_bank_account: string;
    company_name: string;
    created_at: string;
    customer_address: string;
    customer_name: string;
    invoice_date: string;
    invoice_details: unknown[];
    invoice_number: string;
    line_user_avatar?: string | null;
    line_user_name: string;
    payment_date: string;
    payment_type?: string | null;
    quote_id: number;
    status: string;
    total_after_tax?: number;
    total_before_tax?: number;
    total_tax?: number;
    type_of_quote: string;
    updated_at?: string;
    customer: {
        last_name: string;
        first_name: string;
    };
    total_estimate_before_tax: number;
    total_estimate_after_tax: number;
}

interface ISearchValues {
    filter_value: string;
    invoice_start_date: string;
    invoice_end_date: string;
    payment_start_date: string;
    payment_end_date: string;
}

const initialSearchValues: ISearchValues = {
    filter_value: '',
    invoice_start_date: '',
    invoice_end_date: '',
    payment_start_date: '',
    payment_end_date: '',
};

const Partners: React.FC = () => {
    const [invoices, setInvoices] = useState<IInvoiceData[]>([]);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceData | null>(
        null,
    );
    const [idPayment, setIdPayment] = useState<number>(-1);
    const PER_PAGE = 10;
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    const [ModalPayment, openPayment, closePayment, isOpenPayment] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );
    const [searchValues, setSearchValues] =
        useState<ISearchValues>(initialSearchValues);
    const [submitValues, setSubmitValues] =
        useState<ISearchValues>(initialSearchValues);
    const startIndex = (page - 1) * PER_PAGE;
    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };

    const paymentInvoice = async (id) => {
        if (id) {
            const body = {
                id: id,
                status: 'paid',
            };
            try {
                const res = await invoiceApi.paymentAdmin(
                    user?.access_token,
                    body,
                    id,
                );
                if (res) {
                    alertSuccessPopup({
                        title: '支払いに成功しました',
                        confirmButtonText: '閉じる',
                    });
                    fetchInvoices();
                }
            } catch (error) {
                console.log('error', error);
                alertError('支払いに失敗しました');
            }
            setIdPayment(-1);
        }
        setIdPayment(-1);
    };

    const fetchInvoices = async () => {
        try {
            const response = await invoiceApi.getInvoices(user?.access_token, {
                page: page,
                per_page: PER_PAGE,
                filter_value: submitValues.filter_value,
                invoice_start_date: submitValues.invoice_start_date,
                invoice_end_date: submitValues.invoice_end_date,
                payment_start_date: submitValues.payment_start_date,
                payment_end_date: submitValues.payment_end_date,
            });
            const { data } = response;
            if (data) {
                const totalCount = data?.meta?.total_count;
                const totalInteger = Math.floor(totalCount / PER_PAGE);
                const totalSurplus = totalCount % PER_PAGE;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
                setTotalPage(totalPageCustom);
                setInvoices(data.invoices);
            }
        } catch (error) {}
    };

    const handleNumberFormat = (number: number) => {
        return new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'JPY',
        }).format(number);
    };

    useEffect(() => {
        fetchInvoices();
    }, [page, submitValues]);

    const handleSearchInvoices = () => {
        setPage(1);
        setSubmitValues((prev) => ({
            ...prev,
            filter_value: searchValues.filter_value,
            invoice_start_date: searchValues.invoice_start_date,
            invoice_end_date: searchValues.invoice_end_date,
            payment_start_date: searchValues.payment_start_date,
            payment_end_date: searchValues.payment_end_date,
        }));
    };

    const handleDeleteInvoice = async () => {
        if (!selectedInvoice) {
            return;
        }
        try {
            const response = await invoiceApi.deleteInvoice(
                user?.access_token,
                selectedInvoice.id,
            );
            const { data } = response;
            if (data && data.status === 'success') {
                fetchInvoices();
                alertSuccess(`${selectedInvoice.id}を削除しました。`);
            }
        } catch (error) {
            alertError('');
        } finally {
            closeDelete();
            setSelectedInvoice(null);
        }
    };

    return (
        <>
            <div className="container-worker d-flex flex-row">
                <div className="worker-content d-flex flex-column">
                    <div className="flex justify-end mb-[26px]">
                        <button
                            className="modal-create-user-button1 !h-[36px] !rounded-[3px] d-flex flex-row w-[110px] gap-[5px] px-0 py-1"
                            style={{
                                width: '150px',
                                borderRadius: '0px',
                                border: '1px solid #215493',
                                color: '#215493',
                            }}
                            type="button"
                            onClick={() => navigate('/partners/create')}
                        >
                            <img src={PlusBlue} alt="" />
                            新規作成
                        </button>
                    </div>
                    <div className="filterSection flex items-end mb-3">
                        <div className="w-[30%] ml-4">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                協力会社名
                            </p>
                            <Box
                                sx={{
                                    '.select-search__input': {
                                        border: '1px solid #D9D9D9',
                                        boxShadow: 'none',
                                    },
                                    '.select-search__options': {
                                        paddingLeft: 0,
                                    },
                                    '.select-search__option.is-selected': {
                                        background: '#215493',
                                    },
                                }}
                            >
                                <SelectSearch
                                    options={typeOptions}
                                    search
                                    emptyMessage={'該当なし'}
                                    // value={typeSearchValue}
                                    onChange={(value) => {
                                        setPage(1);
                                        // setTypeSearchValue((prev) => {
                                        //     if (prev === value) {
                                        //         return 'all';
                                        //     }
                                        //     return value;
                                        // });
                                    }}
                                />
                            </Box>
                        </div>
                        <div className="w-[30%] ml-4">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                担当者
                            </p>
                            <Box
                                sx={{
                                    '.select-search__input': {
                                        border: '1px solid #D9D9D9',
                                        boxShadow: 'none',
                                    },
                                    '.select-search__options': {
                                        paddingLeft: 0,
                                    },
                                    '.select-search__option.is-selected': {
                                        background: '#215493',
                                    },
                                }}
                            >
                                <SelectSearch
                                    options={[]}
                                    search
                                    emptyMessage={'該当なし'}
                                    // value={typeSearchValue}
                                    onChange={(value) => {
                                        setPage(1);
                                        // setTypeSearchValue((prev) => {
                                        //     if (prev === value) {
                                        //         return 'all';
                                        //     }
                                        //     return value;
                                        // });
                                    }}
                                />
                            </Box>
                        </div>
                        <div className="w-[30%] ml-4">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                エリア
                            </p>
                            <Box
                                sx={{
                                    '.select-search__input': {
                                        border: '1px solid #D9D9D9',
                                        boxShadow: 'none',
                                    },
                                    '.select-search__options': {
                                        paddingLeft: 0,
                                    },
                                    '.select-search__option.is-selected': {
                                        background: '#215493',
                                    },
                                }}
                            >
                                <SelectSearch
                                    options={[]}
                                    search
                                    emptyMessage={'該当なし'}
                                    // value={typeSearchValue}
                                    onChange={(value) => {
                                        setPage(1);
                                        // setTypeSearchValue((prev) => {
                                        //     if (prev === value) {
                                        //         return 'all';
                                        //     }
                                        //     return value;
                                        // });
                                    }}
                                />
                            </Box>
                        </div>

                        <button
                            onClick={handleSearchInvoices}
                            className="modal-create-user-button2 !w-[10%] !min-w-[70px] !h-[32px] !rounded-[3px] ml-3 mb-[2px]"
                        >
                            検索
                        </button>
                    </div>
                    <div className="table-workers d-flex flex-column">
                        <div className="table-content d-flex flex-column">
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 900 }}
                                    aria-label="customized table "
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '5%' }}
                                            >
                                                <div>No</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '11%' }}
                                            >
                                                <div>協力会社ID</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '15%' }}
                                            >
                                                <div>協力会社名</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '8%' }}
                                            >
                                                <div>エリア</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '12%' }}
                                            >
                                                <div>代表者</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '13%' }}
                                            >
                                                <div>役職</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '8%' }}
                                            >
                                                <div>担当者</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '8%' }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {invoices.length > 0 &&
                                            invoices.map((row, idx) => {
                                                return (
                                                    <StyledTableRow
                                                        key={row.id}
                                                    >
                                                        <StyledTableCell
                                                            className="!text-left"
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                                width: '5%',
                                                            }}
                                                        >
                                                            {startIndex +
                                                                idx +
                                                                1}
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '11%',
                                                            }}
                                                        >
                                                            <div></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '15%',
                                                            }}
                                                        >
                                                            <div></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '8%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            ></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '12%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            ></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '13%',
                                                            }}
                                                        >
                                                            <div
                                                                className="text-overflow1"
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            ></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '11%',
                                                            }}
                                                        >
                                                            <div></div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '8%',
                                                            }}
                                                        >
                                                            <div className="flex justify-end items-center">
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 8px 0px 4px',
                                                                    }}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/quote/list/invoice/${row.quote_id}?invoice_id=${row.id}`,
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            PencilIcon
                                                                        }
                                                                        alt="pencil icon"
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 4px',
                                                                    }}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/quote/${row.id}`,
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            EyeIcon
                                                                        }
                                                                        alt="eye icon"
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 4px',
                                                                    }}
                                                                    onClick={() => {
                                                                        openDelete();
                                                                        setSelectedInvoice(
                                                                            row,
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            RedTrash
                                                                        }
                                                                        alt="red trash icon"
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {invoices.length === 0 && (
                                    <div className="text-center p-[10px] w-full">
                                        データがありません。
                                    </div>
                                )}
                            </TableContainer>
                        </div>
                    </div>

                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlinedCustom
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]':
                                            statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalPayment>
                <div
                    className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                    style={{ width: '449px', height: '285px' }}
                >
                    <div className="flex justify-end mt-[10px] cursor-pointer">
                        <CloseOutlined onClick={closePayment} />
                    </div>
                    <div className="flex flex-col justify-center items-center pb-[10px]">
                        <img
                            src={ExclamationIcon}
                            className="w-[80px] h-[80px]"
                            alt="exclamation icon"
                        />
                        <div className="text-[#3A3B3C] font-normal text-center text-[13px] pt-[15px]">
                            見積依頼に対する支払いの確認
                        </div>
                        <div
                            className="flex gap-[10px]"
                            style={{ marginTop: '40px' }}
                        >
                            <button
                                className="modal-create-user-button1 !text-[#3A3B3C] d-flex flex-row !w-[147px] !h-[36px] !rounded-[2px]"
                                onClick={closePayment}
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={() => paymentInvoice(idPayment)}
                                className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[2px]"
                            >
                                確認
                            </button>
                        </div>
                    </div>
                </div>
            </ModalPayment>
            <ModalDelete>
                <div
                    className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                    style={{ width: '449px', height: '285px' }}
                >
                    <div className="flex justify-end">
                        <CloseOutlined
                            onClick={() => {
                                setSelectedInvoice(null);
                                closeDelete();
                            }}
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center pt-[15px] pb-[10px]">
                        <img
                            src={ExclamationIcon}
                            className="w-[80px] h-[80px]"
                            alt="exclamation icon"
                        />
                        <div className="text-[#215493] font-medium text-[16px] pt-[5px]">
                            警告
                        </div>
                        <div className="text-[#3A3B3C] font-normal text-[13px] pt-[5px]">
                            本当に削除しますか?
                        </div>
                        <div
                            className="flex gap-[10px]"
                            style={{ marginTop: '40px' }}
                        >
                            <button
                                className="modal-create-user-button1 !text-[#3A3B3C] d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                                onClick={() => {
                                    setSelectedInvoice(null);
                                    closeDelete();
                                }}
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={handleDeleteInvoice}
                                className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                            >
                                削除
                            </button>
                        </div>
                    </div>
                </div>
            </ModalDelete>
        </>
    );
};

export default Partners;
