import Swal from 'sweetalert2';
import {invoiceStatus} from 'utils/constants';

/*
 * alert
 *
 */
export const alertError = (message: string): void => {
  Swal.fire({
    title: 'エラー',
    text: message,
    icon: 'error',
    confirmButtonText: 'キャンセル',
    confirmButtonColor: '#215493',
  });
};

export const textTrim = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    let slicedText;
    slicedText = text.slice(0, maxLength) + "...";
    return slicedText;
  } else {
    return text;
  }
};

export const alertSuccess = (message: string): void => {
  Swal.fire({
    title: message,
    icon: 'success',
    confirmButtonText: '作成',
    confirmButtonColor: '#215493',
    customClass: {
      title: 'custom-swal-title-class',
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    }
  });
};

export const alertSuccessPopup = (
  {title, confirmButtonText}:
    {title: string, confirmButtonText: string;}
): void => {
  Swal.fire({
    title,
    icon: 'success',
    confirmButtonText,
    confirmButtonColor: '#215493',
    customClass: {
      title: `custom-swal-title-class`,
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    }
  });
};

export const alertSuccessPopup2 = (
  {title, confirmButtonText}:
    {title: string, confirmButtonText: string;}
): void => {
  Swal.fire({
    title,
    icon: 'success',
    confirmButtonText,
    confirmButtonColor: '#215493',
    customClass: {
      title: `custom-swal-title-class text-[#07A315]`,
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    }
  });
};

export const alertPopup = (
  {type, title, confirmButtonText = '閉じる', haveCustomClass}:
    {
      type: 'success' | 'error' | 'warning' | 'info' | 'question',
      title: string,
      confirmButtonText?: string,
      haveCustomClass?: boolean;
    }
): void => {
  Swal.fire({
    title,
    icon: type,
    confirmButtonText,
    confirmButtonColor: '#215493',
    customClass: haveCustomClass ? {
      title: 'custom-swal-title-class',
      icon: 'custom-swal-icon-class',
      confirmButton: 'custom-swal-confirm-btn-class'
    } : undefined
  });
};

export const validateZipcode = (zipcode: string) => {
  return new RegExp('^(^$|[0-9]{3}-[0-9]{4}|ー)$').test(zipcode);
};

export function formatCurrency(value: string | number): string {
  let num = typeof value === 'string' ? parseFloat(value.replace(/[^\d.-]/g, '')) : value;
  num = Math.round(num);
  if (isNaN(num)) return '0';
  return num.toLocaleString('en-US');
}

export const determineStatus = (value: string) => {
  switch (value) {
    case invoiceStatus?.estimate_sent_and_waiting_approval?.value: {
      return {
        jpText: invoiceStatus?.estimate_sent_and_waiting_approval?.jpText,
        color: invoiceStatus?.estimate_sent_and_waiting_approval?.textColor,
        bg: invoiceStatus?.estimate_sent_and_waiting_approval?.bg,
      };
    }
    case invoiceStatus?.estimate_approved?.value: {
      return {
        jpText: invoiceStatus?.estimate_approved?.jpText,
        color: invoiceStatus?.estimate_approved?.textColor,
        bg: invoiceStatus?.estimate_approved?.bg,
      };
    }
    case invoiceStatus?.estimate_rejected?.value: {
      return {
        jpText: invoiceStatus?.estimate_rejected?.jpText,
        color: invoiceStatus?.estimate_rejected?.textColor,
        bg: invoiceStatus?.estimate_rejected?.bg,
      };
    }
    case invoiceStatus?.waiting_for_payment?.value: {
      return {
        jpText: invoiceStatus?.waiting_for_payment?.jpText,
        color: invoiceStatus?.waiting_for_payment?.textColor,
        bg: invoiceStatus?.waiting_for_payment?.bg,
      };
    }
    case invoiceStatus?.paid?.value: {
      return {
        jpText: invoiceStatus?.paid?.jpText,
        color: invoiceStatus?.paid?.textColor,
        bg: invoiceStatus?.paid?.bg,
      };
    }
    case invoiceStatus?.invoice_sent_and_waiting_approval?.value: {
      return {
        jpText: invoiceStatus?.invoice_sent_and_waiting_approval?.jpText,
        color: invoiceStatus?.invoice_sent_and_waiting_approval?.textColor,
        bg: invoiceStatus?.invoice_sent_and_waiting_approval?.bg,
      };
    }
    case invoiceStatus?.invoice_rejected?.value: {
      return {
        jpText: invoiceStatus?.invoice_rejected?.jpText,
        color: invoiceStatus?.invoice_rejected?.textColor,
        bg: invoiceStatus?.invoice_rejected?.bg,
      };
    }
    case invoiceStatus?.invoice_accepted_done?.value: {
      return {
        jpText: invoiceStatus?.invoice_accepted_done?.jpText,
        color: invoiceStatus?.invoice_accepted_done?.textColor,
        bg: invoiceStatus?.invoice_accepted_done?.bg,
      };
    }
    default: {
      return {
        jpText: invoiceStatus?.estimate_sent_and_waiting_approval?.jpText,
        color: invoiceStatus?.estimate_sent_and_waiting_approval?.textColor,
        bg: invoiceStatus?.estimate_sent_and_waiting_approval?.bg,
      };
    }
  }
};
